import React, { type ReactNode } from 'react';
import {
	AppBar,
	Box,
	Container,
	Divider,
	Grid,
	Link,
	Toolbar,
} from '@ayx/onyx-ui';
import {
	Typography,
	UserProfileMenu,
} from '@automation-analytics/component-library';

import { useAuth } from 'stores/auth';
import Footer from 'components/layout/Footer';
import alteryxLogo from 'assets/images/alteryx-logo.svg';
import type { User } from 'types';

function AuthedNavActions({ user }: { user: NonNullable<User> }) {
	return (
		<div className="flex items-center">
			<UserProfileMenu user={user} />
		</div>
	);
}

function GlobalWrapper({ children }: { children: ReactNode }) {
	const { isAuthenticated, session } = useAuth();

	return (
		<Box className="h-screen min-h-screen flex flex-col justify-between">
			<div>
				<AppBar color="default" position="static" elevation={3}>
					<Toolbar variant="dense" className="shadow-app-bar px-3">
						<Grid
							alignItems="center"
							container
							justifyContent="space-between"
							className="py-[5px] min-h-[44px] gap-2"
						>
							<div className="flex flex-col sm:flex-row gap-x-5 items-start sm:items-center">
								<Link
									href="https://www.alteryx.com"
									target="_blank"
									className="h-6"
									data-cy="nav-bar-alteryx-home-link"
								>
									<img
										src={alteryxLogo}
										className="h-full"
										alt="Alteryx"
									/>
								</Link>
								<Divider
									orientation="vertical"
									role="presentation"
									flexItem
									className="bg-divider invisible sm:visible"
								/>
								<Typography
									variant="body1"
									className="font-montserrat font-normal text-base leading-tight py-1"
									skipTranslation
								>
									Server Health Check
								</Typography>
							</div>
							{isAuthenticated() && (
								// session.user will never be null here
								// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
								<AuthedNavActions user={session.user!} />
							)}
						</Grid>
					</Toolbar>
				</AppBar>

				<Container maxWidth="xl" className="py-8 md:px-4">
					{children}
				</Container>
			</div>
			{!isAuthenticated() && <Footer />}
		</Box>
	);
}

export default GlobalWrapper;
