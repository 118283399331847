import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { Button } from '@ayx/onyx-ui';
import {
	GenericPage,
	useEffectOnce,
} from '@automation-analytics/component-library';

import { useAuth } from 'stores/auth';
import { errors } from 'enums';
import { toastError } from 'utils/toasts';
import { ReactComponent as IllustrationLockSvg } from 'assets/images/illustration-lock.svg';

function UnauthorizedPage({ redirectTo }: { redirectTo: string }) {
	const { login, isAuthenticated } = useAuth();
	const navigate = useNavigate();

	const handleLogin = () => {
		login().catch(() => toastError(errors.FAULTY_SIGNIN));
	};

	useEffectOnce(() => {
		// if a user is authed, prevent them from coming back to this page
		if (isAuthenticated()) {
			navigate(redirectTo, { replace: true });
		}
	});

	return (
		<GenericPage
			icon={<IllustrationLockSvg width={208} />}
			title="Sign In"
			text={errors.UNAUTHORIZED}
			actions={
				<>
					<Button
						data-cy="login-page-button-login"
						variant="contained"
						color="primary"
						className="w-24"
						onClick={handleLogin}
					>
						<Trans>Sign In</Trans>
					</Button>
					<Button
						data-cy="login-page-button-sign-up"
						variant="contained"
						className="w-24"
						onClick={handleLogin}
					>
						<Trans>Sign Up</Trans>
					</Button>
				</>
			}
		/>
	);
}

export default UnauthorizedPage;
