import axios from 'axios';

import { BASE_URL, TIMEOUT } from './constants';
import {
	auxResErrorHandler,
	reqSnakeCase,
	resCamelCase,
	resCamelCaseBypass,
	resErrorHandler,
} from './interceptors';

const auxiliaryClient = axios.create({
	baseURL: BASE_URL,
	timeout: TIMEOUT,
	withCredentials: true,
});

const client = axios.create({
	baseURL: BASE_URL,
	timeout: TIMEOUT,
	withCredentials: true,
});

// attach interceptors
auxiliaryClient.interceptors.request.use(reqSnakeCase);
auxiliaryClient.interceptors.response.use(resCamelCase, auxResErrorHandler);

client.interceptors.request.use(reqSnakeCase);
client.interceptors.response.use(resCamelCaseBypass, (error) =>
	resErrorHandler(client, auxiliaryClient, error)
);

export { auxiliaryClient, client };
