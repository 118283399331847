const paths = {
	// utility
	root: '/',
	wildcard: '*',

	// errors
	notFound: '/404',
	error: '/error',
	unauthorizedExternalUser: '/unauthorized',
	unauthorized: '/login',

	// auth utilities
	authorize: '/authorize',
	logout: '/logout',

	// pages
	request: '/request',
	success: '/success',
	submit: '/submit',
	version: '/version',
};

export default paths;
