import { z } from 'zod';

export const userSchema = z.object({
	// strictly required to consider a user 'authed'
	email: z.string().email(),
	firstName: z.string(),
	lastName: z.string(),
	preferredLanguage: z.string().nullable(),
});

// infer types from schemas
export interface User extends z.infer<typeof userSchema> {}
