import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { Button, CircularProgress } from '@ayx/onyx-ui';
import {
	GenericPage,
	useEffectOnce,
} from '@automation-analytics/component-library';

import { useAuth } from 'stores/auth';
import { toastError, toastSuccess } from 'utils/toasts';
import { getUser } from 'services/api';
import { errors, info, paths, successes } from 'enums';
import { setUserId } from 'services/NewRelic';

export default function Authorize({ redirectTo }: { redirectTo: string }) {
	const navigate = useNavigate();
	const { updateSession, session } = useAuth();

	useEffectOnce(() => {
		getUser()
			.then((response) => {
				// success, we have credentials
				const {
					data: { user },
				} = response;

				updateSession(user);
				setUserId(user.email);
			})
			.catch(() => {
				toastError(errors.AUTHORIZE_NO_CREDENTIALS);
				setUserId(null);
				navigate(paths.unauthorized, { replace: true });
			});
	});

	useEffect(() => {
		if (session.user) {
			toastSuccess(successes.LOGGED_IN);
			navigate(redirectTo, { replace: true });
		}
	}, [navigate, redirectTo, session]);

	return (
		<GenericPage
			icon={<CircularProgress size={52} />}
			title="Authorizing..."
			text={info.AUTHORIZING}
			actions={
				<Button
					data-cy="authorize-page-button-home"
					variant="contained"
					color="primary"
					className="min-w-24"
					component={Link}
					to={paths.request}
					replace
				>
					<Trans>Return Home</Trans>
				</Button>
			}
		/>
	);
}
