import { noop } from 'lodash';
import { objectToCamel as tsToCamel } from 'ts-case-convert';

import { paths } from 'enums';

const objectToCamel = (data: unknown) => {
	if (typeof data === 'object' && data !== null) {
		return tsToCamel(data);
	}

	return data;
};

const beginLogout = (): Promise<never> => {
	// there may be concurrent requests that are in flight when we hit this
	// check against the current path to prevent a stuttering effect on the logout page
	if (window.location.pathname !== paths.logout) {
		window.location.pathname = paths.logout;
	}
	// if the component which triggered the original request has not yet unmounted,
	// rejecting an error here will cause the catch block of the original request to fire
	// this could cause toasts to be popped, the error page to flash by, etc.
	// to prevent this, return an unfulfilled promise https://github.com/axios/axios/issues/715#issuecomment-290993181
	return new Promise(noop);
};

export { beginLogout, objectToCamel };
