import getEnvironmentVariable from 'utils/getEnvVariable';

export const BASE_URL = getEnvironmentVariable('REACT_APP_API_BASE');
export const TIMEOUT =
	Number(getEnvironmentVariable('REACT_APP_REQUEST_TIMEOUT')) || 30_000;

// error statuses that trigger an auth token refresh
export const AUTH_ERROR_CODES = [401, 403] as const;

// error messages that trigger a logout
export const JWT_LOGOUT_ERRORS = {
	JWT_MISSING: 'JWT refresh token missing',
	JWT_INVALID: 'JWT refresh token is invalid or expired',
	JWT_NOT_PROVIDED: 'Authentication credentials were not provided.',
	JWT_USER_NOT_FOUND: 'User not found',
} as const;

// error messages that trigger a rejection
export const JWT_REJECT_ERRORS = {
	JWT_PERMISSION_DENIED: 'You do not have permission to perform this action.',
} as const;
