import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { Container, Grid, IconButton, Link } from '@ayx/onyx-ui';
import { Facebook, Instagram, Linkedin, Twitter } from '@ayx/icons';
import { kebabCase } from 'lodash';
import { Typography } from '@automation-analytics/component-library';

export default function Footer() {
	const footerLinks = [
		{
			title: 'About Alteryx',
			links: [
				{
					title: 'Products',
					href: 'https://www.alteryx.com/products',
				},
				{
					title: 'Pricing',
					href: 'https://www.alteryx.com/products/pricing',
				},
				{
					title: 'Free Trial',
					href: 'https://www.alteryx.com/designer-trial/free-trial-alteryx',
				},
				{
					title: 'Starter Kits',
					href: 'https://www.alteryx.com/starter-kit',
				},
				{
					title: 'Customer Stories',
					href: 'https://www.alteryx.com/customer-center',
				},
			],
		},
		{
			title: 'Resources',
			links: [
				{
					title: 'Resources Library',
					href: 'https://www.alteryx.com/resources',
				},
				{
					title: 'Events',
					href: 'https://www.alteryx.com/events',
				},
				{
					title: 'Blog',
					href: 'https://www.alteryx.com/input',
				},
				{
					title: 'Glossary',
					href: 'https://www.alteryx.com/glossary',
				},
			],
		},
		{
			title: 'Support',
			links: [
				{
					title: 'Product Support',
					href: 'https://community.alteryx.com/t5/Support/bd-p/SupportPage',
				},
				{
					title: 'Upgrade Center',
					href: 'https://licenses.alteryx.com/',
				},
				{
					title: 'Help+Documentation',
					href: 'https://help.alteryx.com/',
				},
				{
					title: 'Professional Services',
					href: 'https://www.alteryx.com/resources/professional-services',
				},
				{
					title: 'Product Guides',
					href: 'https://www.alteryx.com/resources/product-guides',
				},
			],
		},
		{
			title: 'Community',
			links: [
				{
					title: 'Community',
					href: 'https://community.alteryx.com/',
				},
				{
					title: 'Academy',
					href: 'https://community.alteryx.com/t5/Alteryx-Academy/ct-p/alteryx-academy',
				},
				{
					title: 'Certification',
					href: 'https://community.alteryx.com/t5/Certification/bd-p/product-certification',
				},
				{
					title: 'Alteryx Advocacy',
					href: 'https://www.alteryx.com/customer-center/alteryx-advocacy',
				},
				{
					title: 'SWAG Store',
					href: 'https://alteryxswag.com/',
				},
			],
		},
		{
			title: 'Company',
			links: [
				{
					title: 'About Us',
					href: 'https://www.alteryx.com/about-us',
				},
				{
					title: 'Careers',
					href: 'https://www.alteryx.com/about-us/careers',
				},
				{
					title: 'Contact Us',
					href: 'https://www.alteryx.com/contact-us',
				},
				{
					title: 'Find a Partner',
					href: 'https://www.alteryx.com/partners/find-a-partner',
				},
				{
					title: 'Become a Partner',
					href: 'https://www.alteryx.com/partners/become-a-partner',
				},
			],
		},
	];

	const lowerFooterLinks = [
		{
			title: 'Terms and Conditions',
			href: 'https://www.alteryx.com/terms-and-conditions',
		},
		{
			title: 'Trust',
			href: 'https://www.alteryx.com/trust',
		},
		{
			title: 'Privacy Policy',
			href: 'https://www.alteryx.com/privacy-policy',
		},
		{
			title: 'Legal',
			href: 'https://www.alteryx.com/legal',
		},
	];

	const socialLinks = [
		{
			title: 'LinkedIn',
			Icon: Linkedin,
			href: 'https://www.linkedin.com/company/alteryx/',
		},
		{
			title: 'Facebook',
			Icon: Facebook,
			href: 'https://www.facebook.com/alteryx',
		},
		{
			title: 'Twitter',
			Icon: Twitter,
			href: 'https://twitter.com/alteryx',
		},
		{
			title: 'Instagram',
			Icon: Instagram,
			href: 'https://www.instagram.com/alteryx/',
		},
	];

	const year = useMemo(() => new Date().getFullYear(), []);

	return (
		<Container maxWidth={false} className="bg-paperBackground">
			<Container maxWidth="xl">
				<Grid container className="py-14">
					{footerLinks.map(({ title, links }) => (
						<Grid item xs={12} sm={6} lg={2} key={kebabCase(title)}>
							<Typography variant="h6" className="mb-4">
								{title}
							</Typography>
							{links.map(({ title: linkTitle, href }) => (
								<Grid
									item
									xs={12}
									className="mb-3 leading-[12px]"
									key={kebabCase(linkTitle)}
								>
									<Link
										href={href}
										className="font-bold text-black text-[12px] my-0"
									>
										<Trans>{linkTitle}</Trans>
									</Link>
								</Grid>
							))}
						</Grid>
					))}
					<Grid item xs={12} md={6} lg={2}>
						<Typography variant="h6" className="pb-4">
							Stay in Touch
						</Typography>
						<Typography variant="h6" className="pb-4">
							17200 Laguna Canyon Road <br />
							Irvine, CA 92618
						</Typography>
						<Typography variant="h6" className="pb-4">
							+1 888 836 4274
						</Typography>
						<Grid item>
							{socialLinks.map(({ href, Icon, title }) => (
								<IconButton
									key={title}
									title={title}
									href={href}
								>
									<Icon className="mr-1" />
								</IconButton>
							))}
						</Grid>
					</Grid>
				</Grid>
				<Grid container className="px-7 flex justify-around mb-10">
					<Typography className="font-light" skipTranslation>
						<Trans>
							© {{ year }} ALTERYX, INC. All rights reserved
						</Trans>
					</Typography>
					<div>
						{lowerFooterLinks.map(({ title, href }) => (
							<Link
								key={kebabCase(title)}
								href={href}
								className="mr-2 md:mr-7 font-bold text-black text-[12px] my-0"
							>
								{title}
							</Link>
						))}
					</div>
				</Grid>
			</Container>
		</Container>
	);
}
