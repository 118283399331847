import axios from 'axios';
import { forIn, kebabCase } from 'lodash';
import Endpoints from './Endpoints';
import { auxiliaryClient, client } from './clients';
import type {
	SSOLoginResponse,
	BackendDeploymentDetails,
	User,
	LogoutPayload,
	ContactInfo,
	PaginatedRequests,
	RequestPayload,
	ResponsesAndFileName,
	ReviewSubmissionResponse,
	SubmissionQuestion,
	UploadMetaData,
	UploadUrlPayload,
} from 'types';

// api functions (auxiliary)
export const getLogin = () => {
	return auxiliaryClient.get<SSOLoginResponse>(Endpoints.oidcLogin, {
		withCredentials: false,
	});
};

export const postRefresh = () => {
	return auxiliaryClient.post(Endpoints.oidcRefresh);
};

export const postLogout = () => {
	return auxiliaryClient.post<LogoutPayload>(Endpoints.oidcLogout);
};

// api functions (normal)
export const getUser = () => {
	return client.get<{ user: User }>(Endpoints.user);
};

export const postRequest = (data: RequestPayload) => {
	return client.post(Endpoints.postRequest, data);
};

export const getRequests = (page: number, rowsPerPage: number) => {
	return client.get<PaginatedRequests>(
		Endpoints.getRequest(page.toString(), rowsPerPage.toString())
	);
};

export const getQuestions = (uuid: string) => {
	return client.get<SubmissionQuestion[]>(Endpoints.questions(uuid));
};

export const getPresignedPostUrl = (filename: string, requestId: string) => {
	return client.post<UploadUrlPayload>(Endpoints.presignedPostUrl, {
		filename,
		requestId,
	});
};

export const getContactInfo = (uuid: string) => {
	return client.get<{ results: ContactInfo }>(Endpoints.contactInfo(uuid));
};

export const postSubmission = (uuid: string, data: ResponsesAndFileName) => {
	return client.post<ReviewSubmissionResponse>(
		Endpoints.submission(uuid),
		data
	);
};

export const uploadFile = (
	signedUploadUrl: string,
	metadata: UploadMetaData,
	file: File
) => {
	const data = new FormData();
	forIn(metadata, (value, key) => {
		data.append(kebabCase(key), value);
	});
	data.append('file', file);
	return axios.post(signedUploadUrl, data, {
		headers: {
			'Content-Type': file.type,
		},
	});
};

export const getBackendDeploymentDetails = () => {
	return client.get<BackendDeploymentDetails>(
		Endpoints.backendDeploymentDetails
	);
};
