import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
	GenericPage,
	useEffectOnce,
} from '@automation-analytics/component-library';

import { useAuth } from 'stores/auth';
import { errors } from 'enums';
import { ReactComponent as IllustrationLockSvg } from 'assets/images/illustration-lock.svg';

function UnauthorizedExternalUserPage({ redirectTo }: { redirectTo: string }) {
	const { isAuthenticated } = useAuth();
	const navigate = useNavigate();

	useEffectOnce(() => {
		// if a user is authed, prevent them from coming back to this page
		if (isAuthenticated()) {
			navigate(redirectTo, { replace: true });
		}
	});

	return (
		<GenericPage
			icon={<IllustrationLockSvg width={208} />}
			title="Unauthorized"
			text={errors.UNAUTHORIZED_EXTERNAL}
			actions={null}
		/>
	);
}

export default UnauthorizedExternalUserPage;
