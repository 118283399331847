const errors = {
	// auth
	REFRESH_TOKEN: 'There was an error refreshing your token',
	AUTHORIZE_NO_CREDENTIALS: 'There was an error fetching user info',
	FAULTY_SIGNIN: 'An error occurred while signing you in',
	// navigation
	PAGE_NOT_FOUND: '404 Page Not Found',
	UNEXPECTED_ERROR: 'An unexpected error occurred',
	UNAUTHORIZED: 'You must be signed in to view that page',
	UNAUTHORIZED_EXTERNAL:
		'Please reach out to your account team if you would like an invitation to complete an Alteryx Server Health Check',
	// form errors
	X_IS_REQUIRED: (question: string) => `${question} is required`,
	FIELD_REQUIRED: 'This field is required',
	FAILED_TO_POST_ANSWERS: 'An error occurred while submitting your answers',
	ERROR_POSTING: 'An error occurred while submitting your info',
	ERROR_UPLOADING: 'An error occurred while uploading your file',
	FIX_BEFORE_SUBMITTING: 'Please fix all errors before submitting',
	ANSWER_REQUIRED_QUESTIONS:
		'Please answer the required questions in red below',
	NON_ZIP_FILE: 'Please select a zip file to upload',
	AYX_EMAILS_ONLY: `Employee emails must end with '@alteryx.com'`,
	NOT_OPT_IN: 'Customer has not opted in to marketing emails',
	INVALID_SFDC_ID: 'SFDC IDs must contain 18 alphanumeric characters',
};

const successes = {
	LOGGED_IN: 'Successfully signed in',
};

const info = {
	AUTHORIZING:
		'If you are not redirected momentarily, click the button below to navigate home',
};

export { errors, successes, info };
